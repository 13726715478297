import React from "react"

const ContactData = ({ phone }) => {
  return (
    <div>
      <p style={{ fontSize: "30px" }} className="text-light">
        {phone}
      </p>
    </div>
  )
}
export default ContactData
