import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "react-bootstrap"
import FacebookIco from "../images/facebook.svg"
import { Button } from "react-bootstrap"

const Facebook = () => {
  const data = useStaticQuery(graphql`
    {
      sanityContacts {
        facebook
      }
    }
  `)
  return (
    <>
      <div data-aos="fade-in" data-aos-delay="200" className="pt-2">
        <a
          href={data.sanityContacts.facebook}
          target="_blank"
          rel="noopener noreferrer"
          className="nounderline"
        >
          <Button variant="outline-secondary" block>
            <Image src={FacebookIco} className="questionico" />
            Facebook страница...
          </Button>
        </a>
      </div>
    </>
  )
}
export default Facebook
