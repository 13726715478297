import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ZapisConsult = () => {
  return (
    <div data-aos="fade-right">
      <Link to="/contacts">
        <StaticImage
          src="../images/zapisatsya-na-konsultatiyu-stepanenko-3-alexander.jpg"
          // width={300}
          quality={70}
          formats={["AUTO", "WEBP", "AVIF"]}
          data-aos="fade-in"
          alt="Степаненко Александр Психолог в Барселоне и онлайн"
        />
      </Link>
    </div>
  )
}
export default ZapisConsult
