import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import { Col, Row, Container } from "react-bootstrap"
import ContactData from "../components/contactdata"
import TopicList from "../components/topicList"
import CheckText from "../components/textwithcheck"
import YouTubePlayer from "../components/youtubeplayer"
import FaQ from "../components/FaQ"
import FacebookLink from "../components/facebook"
import Aos from "aos"
import ZapisConsult from "../components/zapisConsultation"
import PortableText from "react-portable-text"

const Video = ({ data }) => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])
  const { title, header, header2, _rawListOfVideos } = data.sanityVideo
  const serializer = {
    strong: props => <strong className="text-white" {...props} />,
  }
  return (
    <Layout>
      <Seo
        title={title}
        keywords={[
          "психолог",
          "барселона",
          "степаненко",
          "александр",
          "зависимость",
          "алкоголизм",
          "наркомания",
          "помощь",
        ]}
      />
      <Container fluid="xl">
        <Row>
          {/* photo and text block */}
          <Col
            xl={8}
            lg={8}
            md={12}
            sm={12}
            xs={12}
            className="py-5 arrowbackground-1"
          >
            <Container fluid="lg">
              <Row>
                <Col>
                  <div className="text-white">
                    <h1
                      className="display-6 uk-heading-divider"
                      data-aos="fade-in"
                    >
                      {header}
                      <br />
                      <span style={{ fontWeight: "200" }}>{header2}</span>
                    </h1>
                    {_rawListOfVideos.map(video => (
                      <div className="pb-3" key={video._key}>
                        <YouTubePlayer
                          url={video.youtubeLink}
                          data-aos="fade-in"
                        />
                        <section
                          style={{ textTransform: "uppercase" }}
                          className="uk-heading-bullet py-3 text-light display-6"
                          data-aos="fade-right"
                        >
                          {video.youtubeTitle}
                        </section>
                        <section
                          className="pb-3 uk-text-meta uk-heading-divider"
                          data-aos="fade-in"
                        >
                          <PortableText
                            className="uk-text-meta"
                            content={video.body}
                            serializers={serializer}
                          />
                        </section>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          {/* /* basic topics */}
          <Col xl={4} lg={4} md={12} sm={12} xs={12} className="p-5 bg-primary">
            <TopicList />
            <FaQ />
            <FacebookLink />
          </Col>
        </Row>
      </Container>
      <div style={{ width: "100%" }} className="bg-secondary">
        <Container fluid="xl">
          <Row>
            <Col
              xl={8}
              lg={8}
              md={12}
              sm={12}
              xs={12}
              className="py-5 text-right"
            >
              <ZapisConsult />
            </Col>
            <Col
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="bg-primary p-5"
            >
              <ContactData />
            </Col>
          </Row>
        </Container>
      </div>

      <CheckText text="опыт&nbsp;работы более 10&nbsp;лет" />
    </Layout>
  )
}

export const query = graphql`
  {
    sanityVideo {
      title
      header2
      header
      _rawListOfVideos(resolveReferences: { maxDepth: 10 })
      description
    }
  }
`

export default Video
